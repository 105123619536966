<template>
  <div class="mt-4">
    <UsersHead />
    <UsersBody
      :users="users"
      :userService="userService"
      :updateUsers="updateUsers"
    />
  </div>
</template>

<style scoped lang="sass"></style>

<script>
import UsersHead from "./components/UsersHead.vue";
import UsersBody from "./components/UsersBody.vue";
import UserService from "../../services/UserService/UserService";

export default {
  components: { UsersHead, UsersBody },
  name: "UsersPage",
  data() {
    return {
      users: [],
      userService: null,
    };
  },
  mounted() {
    this.userService = new UserService();
    this.getAllUsers();
  },
  methods: {
    async getAllUsers() {
      this.users = await this.userService.allUsers();
    },

    updateUsers(users) {
      this.users = users;
    },
  },
};
</script>
