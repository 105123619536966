<template>
  <div class="container mt-5">
    <div
      class="row"
      v-for="(user, i) in users"
      :key="i"
      style="justify-content: center; justify-content: space-evenly"
    >
      <div class="col-md-4">
        {{ user.first_name }}
      </div>

      <div class="col-md-3">
        {{ user.email }}
      </div>

      <div class="col-md-1" style="text-align: right">
        <span
          class="material-icons-outlined"
          @click="getId(user.id)"
          style="cursor: pointer"
        >
          edit
        </span>
        <span
          class="material-icons-outlined"
          style="cursor: pointer"
          @click="deleteUser(user.id)"
        >
          delete
        </span>
      </div>
      <hr class="mt-2" style="background: #a1000c; width: 82%" />
    </div>
  </div>
</template>

<style scoped lang="sass">
@import "~/public/assets/styles/scss/pages/users.scss"
</style>

<script>
import Swal from "sweetalert2";

export default {
  name: "UsersBody",
  props: ["users", "userService", "updateUsers"],

  methods: {
    async deleteUser(id) {
      Swal.fire({
        title: "Certeza que deseja deletar?",
        text: "Essa ação não poderá ser desfeita",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#BF0C0E",
        cancelButtonColor: "#A9A9A9",
        confirmButtonText: "Deletar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.userService.deleteUser(id);
          Swal.fire("Deleted!", "Usuário deletado com sucesso", "success");
        }
      });
    },

    getId: async function (id) {
      let userId = id;
      if (userId) {
        return this.$router.push({ path: `/profile/${userId}` });
      }
    },
  },
};
</script>
