<template>
  <div class="container mt-4 mb-3">
    <div class="row" style="justify-content: space-around; align-items: center">
      <div>
        <h1 style="font-size: 30px; color: #a1000c; font-weight: bold">
          Usuários
        </h1>
        <p>Veja os usuários do sistema</p>
      </div>
      <div>
        <img src="/assets/img/users.jpg" />
      </div>
    </div>

    <div class="row mt-5" style="justify-content: center">
      <div class="col-8">
        <b-form-input
          id="input-horizontal"
          placeholder="Pesquise usuários..."
          style="border: 1px solid #a1000c"
          class="input px-3"
        ></b-form-input>
      </div>
      <div>
        <b-button variant="danger mr-2" type="submit">Filtrar</b-button>
        <b-button variant="success" type="submit" :to="{ path: '/profile/' }"
          >Novo Usuário</b-button
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
@import "~/public/assets/styles/scss/pages/users.scss"
</style>

<script>
export default {
  name: "UsersHead",
  props: ["users", "userService"],
};
</script>
